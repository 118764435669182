import React, { useEffect, useState, useRef } from "react";

// Email js package.
import emailjs from "emailjs-com";
// React google recaptcha package.
import ReCAPTCHA from "react-google-recaptcha";

const initialState = {
  name: "",
  email: "",
  message: "",
};

// Fields status initial state.
const initialFieldsIsValid = {
  name: false,
  email: false,
  message: false,
};

// Fields focus initial state.
const initialFieldsFocus = {
  name: false,
  email: false,
  message: false,
};

// Initial submit status.
const initialSubmitStatus = {
  isSubmitting: false,
  success: false,
  message: "",
};

// Email Regex.
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const Contact = (props) => {
  // Form ref.
  const formRef = useRef(null);
  // Recpatcha ref.
  const recaptchaRef = useRef(null);

  const [{ name, email, message }, setState] = useState(initialState);

  // Error state.
  const [isFieldValid, setFieldIsValid] = useState(initialFieldsIsValid);
  // Fields focus.
  const [fieldsFocus, setFieldsFocus] = useState(initialFieldsFocus);
  // Submit status.
  const [formStatus, setFormStatus] = useState(initialSubmitStatus);
  // Recaptcha state.
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  // Reset form fields.
  const clearState = () => setState({ ...initialState });

  // Handle form fields change.
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle reacpatcha change.
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  // Effect for name validation.
  useEffect(() => {
    const isValid = name.trim().length > 2;

    setFieldIsValid((prev) => ({
      ...prev,
      name: isValid,
    }));
  }, [name]);

  // Effect for email validation.
  useEffect(() => {
    const isValid = emailRegex.test(email);

    setFieldIsValid((prev) => ({
      ...prev,
      email: isValid,
    }));
  }, [email]);

  // Effect for message validation.
  useEffect(() => {
    // Message length
    const messageLength = message.trim().length;
    // Check.
    const isValid = messageLength >= 10 && messageLength <= 250;

    setFieldIsValid((prev) => ({
      ...prev,
      message: isValid,
    }));
  }, [message]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormStatus((prev) => ({
      ...prev,
      isSubmitting: true,
    }));

    if (!isFieldValid.name || !isFieldValid.email || !isFieldValid.message) {
      return setFormStatus((prev) => ({
        ...prev,
        success: false,
        message: "All fields are required, please fill out them.",
      }));
    }

    // Send email to EmailJs.
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID, // Service ID.
        process.env.REACT_APP_TEMPLATE_ID, // Template ID.
        formRef.current,
        process.env.REACT_APP_PUBLICK_KEY // Publick Key.
      )
      .then(
        (result) => {
          // Set new form status.
          setFormStatus((prev) => ({
            ...prev,
            success: true,
            message: "Message sent successfully.",
          }));

          // Reset form fields.

          clearState();

          // Remove the message after 4s.
          setTimeout(() => {
            setFormStatus({
              success: false,
              message: "",
            });
          }, 5000);

          // Set is submitting status to falase.
          setFormStatus((prev) => ({
            ...prev,
            isSubmitting: false,
          }));

          // Set the fiedls validation to initial state.
          setFieldIsValid(initialFieldsIsValid);

          // Reset recaptcha value to null.
          setRecaptchaValue(null);

          // Uncheck the recaptcha.
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        },
        (error) => {
          // Set new from status state.
          setFormStatus((prev) => ({
            ...prev,
            success: false,
            message: "Failed! something went wrong, please try again.",
          }));

          // Reset recaptcha value.
          setRecaptchaValue(null);

          // Remove the message after 4s.
          setTimeout(() => {
            setFormStatus(initialSubmitStatus);
          }, 5000);
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form
                ref={formRef}
                name="sentMessage"
                validate
                onSubmit={handleSubmit}
              >
                <div className="row">
                  {formStatus.message && (
                    <p
                      className="form-status-message"
                      style={
                        formStatus.success
                          ? {
                              color: "#0d552c",
                              backgroundColor: "rgb(205, 255, 205)",
                            }
                          : {
                              color: "rgb(122, 12, 12)",
                              backgroundColor: "rgb(251, 226, 226)",
                            }
                      }
                    >
                      {formStatus.message}
                    </p>
                  )}
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className={`form-control ${
                          !isFieldValid.name &&
                          fieldsFocus.name &&
                          name &&
                          "border-error"
                        }`}
                        placeholder="Name"
                        value={name}
                        onChange={handleChange}
                        onFocus={() =>
                          setFieldsFocus((prev) => ({ ...prev, name: true }))
                        }
                      />
                      {!isFieldValid.name && fieldsFocus.name && name && (
                        <p className="help-block text-danger">
                          Name must be at least 3 characters.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className={`form-control ${
                          !isFieldValid.email &&
                          fieldsFocus.email &&
                          email &&
                          "border-error"
                        }`}
                        placeholder="Email"
                        value={email}
                        onChange={handleChange}
                        onFocus={() =>
                          setFieldsFocus((prev) => ({
                            ...prev,
                            email: true,
                          }))
                        }
                      />
                      {!isFieldValid.email && fieldsFocus.email && email && (
                        <p className="help-block text-danger">
                          Email is required.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    style={{ resize: "none" }}
                    className={`form-control ${
                      !isFieldValid.message &&
                      fieldsFocus.message &&
                      message &&
                      "border-error"
                    }`}
                    rows="4"
                    minLength={10}
                    maxLength={250}
                    placeholder="Message"
                    value={message}
                    onChange={handleChange}
                    onFocus={() =>
                      setFieldsFocus((prev) => ({
                        ...prev,
                        message: true,
                      }))
                    }
                  ></textarea>
                  <div className="textarea-details">
                    <p className="help-block text-danger">
                      {!isFieldValid.message &&
                        fieldsFocus.message &&
                        message &&
                        "Message must be at least 10 character."}
                    </p>
                    <p
                      className="textarea-length"
                      style={
                        isFieldValid.message
                          ? {
                              color: "green",
                            }
                          : {
                              color: "#666",
                              fontSize: "1.2rem",
                              fontWeight: "500",
                              marginTop: ".5rem",
                              marginBottom: "0",
                            }
                      }
                    >
                      {message?.length} / 250 characters.
                    </p>
                  </div>
                </div>
                {/* React recpatcha component. */}
                <div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} // Recaptcha site key.
                    onChange={(value) => handleRecaptchaChange(value)}
                  />
                </div>
                <button
                  type="submit"
                  disabled={
                    formStatus.isSubmitting ||
                    !recaptchaValue ||
                    !isFieldValid.name ||
                    !isFieldValid.email ||
                    !isFieldValid.message
                  }
                  className="btn btn-custom btn-lg"
                >
                  {formStatus.isSubmitting ? "Submitting..." : "Send Message"}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? (
                  <a
                    href="https://www.google.com/maps/place/Andalus,+Baghdad,+Baghdad+Governorate/@33.3239344,44.3238043,16z/data=!3m1!4b1!4m6!3m5!1s0x15577e8f722abfcf:0x380f189accdcec48!8m2!3d33.3238762!4d44.334299!16s%2Fg%2F1tjbvf2v?entry=ttu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.data.address}
                  </a>
                ) : (
                  "loading"
                )}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? (
                  <a href={`tel:${props.data.phone_1}`}>{props.data.phone_1}</a>
                ) : (
                  "loading"
                )}
              </p>
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? (
                  <a href={`tel:${props.data.phone_2}`}>{props.data.phone_2}</a>
                ) : (
                  "loading"
                )}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? (
                  <a href={`mailto:${props.data.email}`}>{props.data.email}</a>
                ) : (
                  "loading"
                )}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href="/" rel="noreferrer">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/" rel="noreferrer">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/" rel="noreferrer">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/" rel="noreferrer">
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; {String(new Date().getFullYear())} Spirits Of Togetherness.
            All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
