import React from "react";
import AswarLogo from "../assets/AswarLogo";
import FarberLogo from "../assets/FarberLogo";
import MorrellLogo from "../assets/MorrellLogo";
import CamScanLogo from "../assets/CamScanLogo";
import MilistoneLogo from "../assets/MilistoneLogo";
import ProsKitLogo from "../assets/ProsKitLogo";
import AvigilionLogo from "../assets/AvigilionLogo";
import TelevesLogo from "../assets/TelevesLogo";
import VoltaLogo from "../assets/VoltaLogo";

export const Gallery = (props) => {
  const images = [
    <FarberLogo />,
    <MorrellLogo />,
    <CamScanLogo />,
    <AswarLogo />,
    <MilistoneLogo />,
    <ProsKitLogo />,
    <AvigilionLogo />,
    <TelevesLogo />,
    <VoltaLogo />,
  ];
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Agencies</h2>
          <p>
            Spirits of Togetherness Company owns a wide range of agencies in
            technology and contracting fields, offering integrated services that
            meet market needs with the highest standards of quality and
            professionalism.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {images.map((img, index) => (
              <span key={index}>{img}</span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
