import React from "react";

export const Header = (props) => {
  const splittedHeader = props.data?.title?.split("");

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                {"Welcome To".split("").map((item, index) => (
                  <span key={index} className={`welcome-word ${item}-${index}`}>
                    {item}
                  </span>
                ))}
                <h1 className="HeaderTitle">
                  {splittedHeader?.map((item, index) => (
                    <span key={index} className="animate">
                      {item}
                    </span>
                  ))}
                  <span></span>
                </h1>
                <p className="HeaderDesc">
                  {props.data ? props.data.paragraph : "Loading"}
                </p>
                <a href="#about" className="btn btn-custom btn-lg page-scroll">
                  Learn More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
