import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center" style={{ padding: "9rem 0" }}>
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Our Value</h2>
        </div>
        <div className="features-container">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="feature-card"
                  style={{ margin: "2rem 0", lineHeight: "2.2rem" }}
                >
                  {" "}
                  <i className={d.icon}></i>
                  <h3 style={{ fontSize: "2.2rem", lineHeight: "3.2rem" }}>
                    {d.title}
                  </h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
